import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import Toolbar from '@mui/material/Toolbar';
import {
  DataGrid,
  GridActionsCellItem,
  GridEventListener,
  GridColDef,
  GridRowId,
  GridRowModesModel,
  selectedGridRowsSelector,
  gridFilteredSortedRowIdsSelector,
  GridToolbar,
} from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';

import { Alert, Snackbar } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import '../App.css';
import {
  fetchReferrals, deleteReferrals, Referral, refreshReferrals,
} from '../features/referrals/refarrals';
import { RootState } from '../app/store';
import { getOurDealStage } from '../utils';

const Referrals = () => {
  const referralState = useAppSelector((state: RootState) => state.referrals);
  const dispatch = useAppDispatch();
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [rows, setRows] = useState(referralState.value);
  const [showRefreshed, setShowRefreshed] = useState(false);
  const [isRefreshed, setIsRefreshed] = useState(false);

  useEffect(() => { dispatch(fetchReferrals()); }, []);
  useEffect(() => setRows(referralState.value), [referralState.value]);

  const handleDeleteClick = (id: GridRowId) => () => {
    const rowToDelete = rows.find((row) => row.cb_id === id);
    if (rowToDelete) {
      setRows(rows.filter((row) => row.cb_id !== id));
      dispatch(deleteReferrals(rowToDelete));
    }
  };

  const columns: GridColDef<Referral>[] = [
    {
      field: 'building_id',
      headerName: 'Building ID',
      editable: false,
      width: 160,
    },
    {
      field: 'service_id',
      headerName: 'Service ID',
      editable: false,
      width: 200,
    },
    {
      field: 'cb_id',
      headerName: 'ChargeBeeId',
      editable: false,
      width: 160,
    },
    {
      field: 'referrer_email',
      headerName: 'Email',
      editable: false,
      width: 200,
    },
    {
      field: 'referral_code',
      headerName: 'Referral Code',
      editable: false,
      width: 100,
    },
    {
      field: 'first_name',
      headerName: 'First Name',
      editable: false,
      width: 120,
    },
    {
      field: 'last_name',
      headerName: 'Last Name',
      editable: false,
      width: 120,
    },

    {
      field: 'redeemed',
      headerName: 'Redeemed',
      editable: false,
      width: 80,
      valueGetter: ((params) => referralState.value.filter(
        (referral) => referral.referral_code === params.row.referral_code && referral.redeemed,
      ).length),
    },
    {
      field: 'pending',
      headerName: 'Pending',
      editable: false,
      width: 80,
      valueGetter: ((params) => referralState.value.filter(
        (referral) => (referral.referral_code === params.row.referral_code)
        && !referral.redeemed
        && (referral.referral_code !== referral.sk)
        && (referral.referrer_email !== referral.sk),
      ).length),
    },

    {
      field: 'id', headerName: 'CB SubId', width: 80, renderCell: (params) => (<a target="_blank" href={`https://pineapple.chargebee.com/d/subscriptions/${params.row?.cb_sub_id}`} rel="noreferrer">{params.row?.cb_active}</a>),
    },
    {
      field: 'hubspot_deal_id',
      headerName: 'Hubspot Deal ID',
      width: 50,
      renderCell: (params) => (<a target="_blank" href={`https://app.hubspot.com/contacts/8498425/record/0-3/${params.row?.hubspot_deal_id}`} rel="noreferrer">{getOurDealStage(params.row?.hubspot_deal_stage)}</a>),
    },
    {
      headerName: 'Delete',
      field: 'actions',
      type: 'actions',
      width: 50,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={handleDeleteClick(params.row.cb_id)}
        />,
      ],
    },
  ];

  const navigate = useNavigate();

  const handleCellClick: GridEventListener<'cellClick'> = (params) => {
    if (params.colDef.headerName === 'Pending') {
      navigate(`referral_report/${params.row.referral_code}?showPending=true`);
    } else if (params.colDef.headerName === 'Redeemed') {
      navigate(`referral_report/${params.row.referral_code}`);
    }
  };

  const handleRefresh = () => {
    setShowRefreshed(true);
    dispatch(refreshReferrals());
    setIsRefreshed(true);
  };

  const handleRefreshClose = (_event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') return;
    setShowRefreshed(false);
  };

  const getSelectedRowsToExport = ({
    apiRef,
  }: any): GridRowId[] => {
    const selectedRowIds = selectedGridRowsSelector(apiRef);
    if (selectedRowIds.size > 0) {
      return Array.from(selectedRowIds.keys());
    }

    return gridFilteredSortedRowIdsSelector(apiRef);
  };

  return (
    <Box>
      <Grid container>
        <Grid item sm={12}>
          <Box sx={{ paddingTop: '5%', width: '85%', margin: 'auto' }}>
            <Button disabled={isRefreshed} variant="contained" onClick={() => handleRefresh()}>Enqueue refresh</Button>
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={showRefreshed}
              autoHideDuration={3000}
              onClose={() => handleRefreshClose()}
            >
              <Alert onClose={handleRefreshClose} severity="success" variant="filled">
                Refresh is scheduled, please give it 5-10 minutes.
              </Alert>
            </Snackbar>

            <Toolbar />
            <DataGrid
              getRowId={(row) => row.cb_id}
              autoHeight
              loading={referralState.status === 'loading'}
              rows={rows.filter((row) => row.sk === row.referral_code)}
              columns={columns}
              onCellClick={handleCellClick}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: { printOptions: { getRowsToExport: getSelectedRowsToExport } },
              }}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'speed', sort: 'asc' }],
                },
              }}
              pageSizeOptions={[100]}
              componentsProps={{
                toolbar: { setRows, setRowModesModel },
              }}
            />
          </Box>
        </Grid>
        <Grid item sm={10} />
        <Grid item sm={2}>
          <Button href="/dashboard/referrals/demo">Demo</Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Referrals;
