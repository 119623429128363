import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Client } from '../../http';
import { RootState } from '../../app/store';

export interface Referral {
  referral_code: string;
  referrer_email: string;
  cb_id: string;
  sk: string;
  first_name: string;
  last_name: string;
  redeemed: number;
  building_id: string;
  service_id: string;
  referral_object_type: number;
  redeemInChargeBee: string;
  cb_active: string;
  cb_sub_start_date: string;
  cb_sub_id: string;
  hubspot_deal_id: string;
  hubspot_deal_stage: string;
}

export interface referralsState {
  value: Array<Referral>;
  status: 'idle' | 'loading' | 'failed';
}

export const newReferrals = () => ({
  building_id: '',
  service_id: '',
  referral_code: '',
  referrer_email: '',
  cb_id: '',
  first_name: '',
  last_name: '',
  sk: '',
  redeemed: 0,
  referral_object_type: 0,
  redeemInChargeBee: '',
  hubspot_deal_id: '',
  hubspot_deal_stage: '',
});

const initialState: referralsState = {
  value: [],
  status: 'idle',
};

export const fetchReferrals = createAsyncThunk(
  'referrals/fetchReferralss',
  async () => {
    const response = await Client.get<Referral[]>('/api/v1/admin/referrals');
    return response.data;
  },
);

export const refreshReferrals = createAsyncThunk(
  'referrals/refreshReferralss',
  async () => {
    const response = await Client.get<Referral[]>('/api/v1/admin/referrals/refresh');
    return response.data;
  },
);

export interface ReferralPayload {
  code: string
  showPending: boolean
  sales: boolean
}

export const fetchReferralReport = createAsyncThunk(
  'referrals/fetchReferralReport',
  async ({ code, showPending, sales }: ReferralPayload) => {
    const response = await Client.get<Referral[]>(`/api/v1/admin/referrals/${sales ? 'commission_sales/' : ''}referral_report/${code}${showPending ? '/pending' : ''}`);
    return response.data;
  },
);

export const fetchCommissionSales = createAsyncThunk(
  'referrals/fetchCommissionSales',
  async () => {
    const response = await Client.get<Referral[]>('/api/v1/admin/referrals');
    return response.data;
  },
);

export const deleteReferrals = createAsyncThunk<
  Referral,
  Referral,
  {state: RootState}
>('referrals/deleteReferrals', async (referral: Referral) => {
  await Client.delete(`/api/v1/admin/referrals/${referral.referral_code}`);
  return referral;
});

export const deleteReferralBySk = createAsyncThunk<
  Referral,
  Referral,
  {state: RootState}
>('referrals/deleteReferralsBySk', async (referral: Referral) => {
  await Client.delete(`/api/v1/admin/referrals/${referral.referral_code}/${referral.sk.replaceAll('#', '--')}`);
  return referral;
});

export const redeemReferral = createAsyncThunk<
  Referral,
  Referral,
  {state: RootState}
>('referrals/redeemReferrals', async (referral: Referral) => {
  const response = await Client.get<Referral>(`/api/v1/admin/referrals/redeem/${referral.referral_code}/${referral.sk.replace('#', '--')}/${referral.redeemInChargeBee}`);
  return response.data;
});

export interface CreateReferralBySubscription {
  id: string
  plan_id: string
  start_date: number,
  customer_id: string,
  plan_amount: number,
  status: string,
  created_at: number,
  started_at: number,
  activated_at: number,
  sid: string,
  code: string,
  hubspot_deal_id: string,
}

export const createAndRedeem = createAsyncThunk('referrals/createAndRedeem', async (sub: CreateReferralBySubscription) => {
  const response = await Client.post<Referral>('/api/v1/admin/referrals/create_and_redeem', { ...sub });
  return response.data;
});

export const referralsSlice = createSlice({
  name: 'referrals',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(fetchReferralReport.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchReferralReport.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = [...action.payload];
      })
      .addCase(fetchReferralReport.rejected, (state) => {
        state.status = 'failed';
      })

      .addCase(refreshReferrals.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(refreshReferrals.fulfilled, (state, action) => {
        state.status = 'idle';
      })
      .addCase(refreshReferrals.rejected, (state) => {
        state.status = 'failed';
      })

      .addCase(fetchReferrals.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchReferrals.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = [...action.payload.filter((val) => val.referral_object_type !== 1)];
      })
      .addCase(fetchReferrals.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchCommissionSales.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCommissionSales.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = [...action.payload.filter((val) => val.referral_object_type === 1)];
      })
      .addCase(fetchCommissionSales.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(deleteReferrals.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteReferrals.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = state.value.flatMap((v) => (v.sk === action.payload.sk ? [] : [v]));
      })
      .addCase(deleteReferrals.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(deleteReferralBySk.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteReferralBySk.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = state.value.flatMap((v) => (v.sk === action.payload.sk ? [] : [v]));
      })
      .addCase(deleteReferralBySk.rejected, (state) => {
        state.status = 'failed';
      })

      .addCase(redeemReferral.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(redeemReferral.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = state.value.flatMap((v) => (v.sk === action.payload.sk ? [action.payload] : [v]));
      })
      .addCase(redeemReferral.rejected, (state) => {
        state.status = 'failed';
      })

      .addCase(createAndRedeem.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createAndRedeem.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = state.value.flatMap((v) => (v.sk === action.payload.sk ? [action.payload] : [v]));
      })
      .addCase(createAndRedeem.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const selectReferrals = (state: RootState) => state.referrals.value;
export default referralsSlice.reducer;
